.F1Form {
	max-width: 570px;	
	width: 100%;
	margin: auto;
	-webkit-font-smoothing: antialiased;
}

.F1FormInner {
	background: #fff;
	box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
	border-radius: 10px;
	padding: 20px;
}

.F1Form button {
	width: 100%;
	height: 55px;
	background-image: linear-gradient(to right bottom, #fd696b, #fa616e, #f65871, #f15075, #ec4879);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border: none;
	border-radius: 4px;
	box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
	padding: 12px 16px;
	margin-top: 20px;
	font-size: 16px;
	font-weight: 600;
	color: #fff;
	cursor: pointer;
	transition: all 0.2s ease;
}

.F1Form button:disabled, 
.F1Form button[disabled] {
	cursor: not-allowed;
	pointer-events: all !important;
	background-image: linear-gradient(to right bottom, #c4c4c4, #ababab, #a8a8a8, #8f8f8f);
}

.F1Form input {
	width: 100%;
	height: 50px;
	background: #fafafa;
	border-radius: 5px;
	box-shadow: none;
	transition: all 0.3s ease-in-out;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 18px;
	padding: 5px 15px;
	color: #1a3b5d;	
}
.F1Form input:hover,
.F1Form input:focus {
	border-color: #3d9cff;
}
.F1Form input:focus {
	box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
	border-color: #5a69ba;
}
.F1Form input:not(:placeholder-shown):valid:not(:focus) {
	border-color: mediumseagreen;
}
.F1Form input:not(:placeholder-shown):invalid {
	border-color: tomato;
}

.F1Form input[type="tel"] {
	-webkit-appearance: none;
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='90%' width='90%'><text x='4' y='60%' fill='gray'>+</text></svg>");
	background-repeat: no-repeat;
    padding-left: 18px;
}
