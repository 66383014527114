@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700|Space+Mono:400,400i,700,700i&display=swap");

body, html {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
}
body {
	display: flex;
	justify-content: center;
	align-items: center;
	display: -webkit-flex;
	-webkit-align-items: center;
	-webkit-justify-content: center;
	-webkit-flex-direction: column;
	background-image: linear-gradient(to right top, #a2beeb, #8ecfee, #8edde5, #a4e7d5, #c7eec7);
	flex-direction: column;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
	box-sizing: border-box;
	font-family: 'Space Mono', monospace;
}