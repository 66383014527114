h3.StripeFormTitle {
	text-align: center
}

form.StripeForm {
	align-self: center;
	align-content: center;
	align-items: center;
	justify-content: center;
	max-width: 570px;
	width: 100%;
	margin: auto;
	background-color: #ffffff;
	border-radius: 7px;
	padding: 40px;
	-webkit-font-smoothing: antialiased;
}

form.StripeForm .StripeFormElement {
	margin-bottom: 24px;
}

form.StripeForm .StripeFormMessage {
	color: rgb(105, 115, 134);
	font-size: 16px;
	line-height: 20px;
	padding-top: 12px;
	text-align: center;
}

form.StripeForm button {
	width: 100%;
	background-image: linear-gradient(to right bottom, #fd696b, #fa616e, #f65871, #f15075, #ec4879);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;	
	box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
	border-radius: 4px;
	border: 0;
	padding: 12px 16px;
	color: #ffffff;
	font-size: 16px;
	font-weight: 600;
	transition: all 0.2s ease;
	cursor: pointer;
	display: block;
}

form.StripeForm button:hover {
	filter: contrast(170%);
}

form.StripeForm button:disabled {
	opacity: 0.5;
	cursor: default;
}

form.StripeForm .StripeFormSpinner,
form.StripeForm .StripeFormSpinner:before,
form.StripeForm .StripeFormSpinner:after {
	border-radius: 50%;
}

form.StripeForm .StripeFormSpinner {
	color: #ffffff;
	font-size: 22px;
	text-indent: -99999px;
	margin: 0px auto;
	position: relative;
	width: 20px;
	height: 20px;
	box-shadow: inset 0 0 0 2px;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}

form.StripeForm .StripeFormSpinner:before,
form.StripeForm .StripeFormSpinner:after {
	position: absolute;
	content: '';
}

form.StripeForm .StripeFormSpinner:before {
	width: 10.4px;
	height: 20.4px;
	background: #fd696b;
	border-radius: 20.4px 0 0 20.4px;
	top: -0.2px;
	left: -0.2px;
	-webkit-transform-origin: 10.4px 10.2px;
	transform-origin: 10.4px 10.2px;
	-webkit-animation: loading 2s infinite ease 1.5s;
	animation: loading 2s infinite ease 1.5s;
}

form.StripeForm .StripeFormSpinner:after {
	width: 10.4px;
	height: 10.2px;
	background: #fd696b;
	border-radius: 0 10.2px 10.2px 0;
	top: -0.1px;
	left: 10.2px;
	-webkit-transform-origin: 0px 10.2px;
	transform-origin: 0px 10.2px;
	-webkit-animation: loading 2s infinite ease;
	animation: loading 2s infinite ease;
}

@keyframes loading {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}