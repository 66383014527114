.App {
	margin-right: 10px;
	margin-left: 10px;
}

h1 {
	font-size: 100%;
}

h3 {
	font-size: 100%;
}

.AppTitle {
	text-align: center;
	margin-bottom: 22px;
	color: #162969;
}

.AppPaymentHeader {
	text-align: center;
	color: lightsteelblue
}

.AppCopyrightFooter {
	text-align: center;
}